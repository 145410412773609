<template>
	<v-container class="mb-6" style="position: fixed; top: 0; z-index: 9">
		<v-row justify="end" no-gutters>
			<v-col cols="6">
				<v-img :src="logo" cover class="rounded-pill k-logo"/>
			</v-col>
			<v-col cols="6">
				<v-row align="center" justify="center">
					<v-col>
						<div id="main-doc" v-if="!isWidthSizeLower">
							<nav id="nav-bar">
								<v-row align="center">
									<v-col cols="2" align="center">
										<v-select density="compact" v-model="selectedLanguage" :items="['DE', 'EN', 'TR']" variant="outlined" class="language-select"/>
									</v-col>
									<v-col cols="10">
										<ul id="nav-ul">
											<a class="nav-point" v-for="nav in navigation" :key="nav.link + '_id'" @click="scrollIntoSection(nav.link)">
												<li id="nav-li" v-if="selectedLanguage == 'DE'">
													<v-icon :icon="nav.icon"/> {{nav.title}}
                                                </li>
                                                <li id="nav-li" v-if="selectedLanguage == 'EN'">
													<v-icon :icon="nav.icon"/> {{nav.titleEN}}
                                                </li>
                                                <li id="nav-li" v-if="selectedLanguage == 'TR'">
													<v-icon :icon="nav.icon"/> {{nav.titleTR}}
                                                </li>
											</a>
										</ul>
									</v-col>
								</v-row>
							</nav>
						</div>
						<div id="main-doc-mini" v-else>
						<nav id="nav-bar">
                            <v-row align="center">
									<v-col cols="7" align="center">
										<v-select  density="compact" v-model="selectedLanguage" :items="['DE', 'EN', 'TR']" variant="outlined" class="language-select"/>
									</v-col>
									<v-col cols="5">
								<v-menu>
								<template v-slot:activator="{ props }">
									<v-app-bar-nav-icon v-bind="props"/>
								</template>
								<v-list>
									<v-list-item @click="scrollIntoSection(nav.link)" v-for="nav in navigation" :key="nav.link + '_id'">
										<v-list-item-title v-if="selectedLanguage == 'DE'">{{ nav.title }}</v-list-item-title>
                                        <v-list-item-title v-if="selectedLanguage == 'EN'">{{ nav.titleEN }}</v-list-item-title>
                                        <v-list-item-title v-if="selectedLanguage == 'TR'">{{ nav.titleTR }}</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
									</v-col>
								</v-row>
						</nav>
					</div>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</v-container>
<section id="home">
	<v-parallax height="100vh" :src="homeImage"/>
</section>
<section id="aboutme" style="min-height: 100vh" v-if="!isWidthSizeLower">
	<div class="blocker"/>
	<v-container>
		<v-row justify="center">
			<v-container>
				<v-row >
					<v-col cols="4" >
						<v-card elevation="16" height="80vh">
							<v-img height="80vh" cover :src="aboutMeImage"/>
						</v-card>
					</v-col>
					<v-col cols="8">
						<v-card elevation="16" style="min-height: 80vh;">
							<v-row>
								<v-col>
									<v-card elevation="0">
										<template v-slot:title>
										<p class="aboutme-title own-font" >Yusuf Kavalci</p>
									</template>
									<template v-slot:subtitle>
									<p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'DE'">STEUERBERATER</p>
                                    <p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'EN'">CERTIFIED TAX ADVISOR</p>
                                    <p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'TR'">VERGİ DANIŞMANI</p>
								</template>
								<template v-slot:text>
								<v-row>
									<v-col align="center">
										<p class="begining-text own-font" v-if="selectedLanguage == 'DE'">
                                            Als Steuerberater ist es meine Leidenschaft, meinen Mandanten dabei zu helfen, Ihre steuerlichen Angelegenheiten zu optimieren und finanzielle Sicherheit zu erreichen. Mit einem individuellen Beratungsansatz und einem tiefen Verständnis für die komplexen Anforderungen des Steuerrechts stehe ich Ihnen zur Seite.
                                        </p>
                                        <p class="begining-text own-font" v-if="selectedLanguage == 'EN'">
                                            As a certified tax advisor, it is my passion to assist my clients in optimizing their tax matters and achieving financial security. With a personalized consulting approach and a deep understanding of the complex requirements of tax law, I am here to support you.
                                        </p>
                                        <p class="begining-text own-font" v-if="selectedLanguage == 'TR'">
                                            Vergi danışmanı olarak, müşterilerimin vergi işlerini optimize etmelerine ve finansal güvenliklerini sağlamalarına yardımcı olmak benim tutkum. Bireysel danışmanlık yaklaşımı ve vergi hukukunun karmaşık gereksinimlerine derin bir anlayışla size destek sağlıyorum.
                                        </p>
									</v-col>
								</v-row>
							</template>
						</v-card>
                        
					</v-col>
                    
				</v-row>
				<v-divider inset/>
				<v-row>
					<v-col>
						<v-card elevation="0">
							<template v-slot:title>
							<p class="aboutme-title own-font" v-if="selectedLanguage == 'DE'">Beruflicher Werdegang</p>
                            <p class="aboutme-title own-font" v-if="selectedLanguage == 'EN'">Professional Background</p>
                            <p class="aboutme-title own-font" v-if="selectedLanguage == 'TR'">İş Geçmişi</p>
						</template>
						<template v-slot:text>
						<v-list lines="one" class="own-font begining-text">
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">Studium der Betriebswirtschaftslehre an der OTH Amberg-Weiden</p>
                                <p v-if="selectedLanguage == 'EN'">Study of Business Administration at OTH Amberg-Weiden.</p>
                                <p v-if="selectedLanguage == 'TR'">OTH Amberg-Weiden'de İşletme Yönetimi eğitimi.</p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">Steuerberater seit 2019 (Mitglied Steuerberaterkammer München)</p>
                                <p v-if="selectedLanguage == 'EN'">Certified tax advisor since 2019 (Member of the Munich Chamber of Tax Consultants).</p>
                                <p v-if="selectedLanguage == 'TR'">2019 yılından beri vergi danışmanıyım (Münih Vergi Danışmanları Odası üyesi).</p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">Über 10 Jahre Berufserfahrung in einer der weltweit größten internationalen Wirtschaftsprüfungs- / Steuerberatungsgesellschaft („Big Four“)</p>
                                <p v-if="selectedLanguage == 'EN'">Over 10 years of professional experience in one of the world's largest international auditing/tax consulting firms ("Big Four").</p>
                                <p v-if="selectedLanguage == 'TR'">Dünyanın en büyük uluslararası denetim/vergi danışmanlık firmalarından birinde 10 yıldan fazla mesleki deneyime sahibim ("Big Four").</p>
							</v-list-item>
						</v-list>
					</template>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</v-col>
</v-row>
</v-container>
</v-row>
</v-container>
</section>
<section id="aboutme" style="min-height: 100vh" v-else>
	<div class="blocker"/>
	<v-container>
		<v-row justify="center">
			<v-container>
                <v-row>
                    	<v-col  >
						<v-card elevation="16" height="80vh">
							<v-img height="80vh" cover :src="aboutMeImage"/>
						</v-card>
					</v-col>
                </v-row>
				<v-row >
					<v-col>
						<v-card elevation="16" style="min-height: 80vh;">
							<v-row>
								<v-col>
									<v-card elevation="0">
										<template v-slot:title>
										<p class="aboutme-title own-font" >Yusuf Kavalci</p>
									</template>
									<template v-slot:subtitle>
									<p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'DE'">STEUERBERATER</p>
                                    <p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'EN'">CERTIFIED TAX ADVISOR</p>
                                    <p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'TR'">YEMINLI MALI MÜŞAVIR</p>
								</template>
								<template v-slot:text>
								<v-row>
									<v-col align="center">
										<p class="begining-text own-font" v-if="selectedLanguage == 'DE'">
                                            Als Steuerberater ist es meine Leidenschaft, meinen Mandanten dabei zu helfen, ihre steuerlichen Angelegenheiten zu optimieren und finanzielle Sicherheit zu erreichen. Mit einem individuellen Beratungsansatz und einem tiefen Verständnis für die komplexen Anforderungen des Steuerrechts stehe ich Ihnen zur Seite.
                                        </p>
                                        <p class="begining-text own-font" v-if="selectedLanguage == 'EN'">
                                            As a certified tax advisor, it is my passion to assist my clients in optimizing their tax matters and achieving financial security. With a personalized consulting approach and a deep understanding of the complex requirements of tax law, I am here to support you.
                                        </p>
                                        <p class="begining-text own-font" v-if="selectedLanguage == 'TR'">
                                            Yeminli mali müşavir olarak, müşterilerimin vergi işlerini optimize etmelerine ve finansal güvenliklerini sağlamalarına yardımcı olmak benim tutkum. Bireysel danışmanlık yaklaşımı ve vergi hukukunun karmaşık gereksinimlerine derin bir anlayışla size destek sağlıyorum.
                                        </p>
									</v-col>
								</v-row>
							</template>
						</v-card>
                        
					</v-col>
                    
				</v-row>
				<v-divider inset/>
				<v-row>
					<v-col>
						<v-card elevation="0">
							<template v-slot:title>
							<p class="aboutme-title own-font" v-if="selectedLanguage == 'DE'">Beruflicher Werdegang</p>
                            <p class="aboutme-title own-font" v-if="selectedLanguage == 'EN'">Professional Background</p>
                            <p class="aboutme-title own-font" v-if="selectedLanguage == 'TR'">İş Geçmişi</p>
						</template>
						<template v-slot:text>
						<v-list lines="one" class="own-font begining-text">
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">Studium der Betriebswirtschaftslehre an OTH Amberg-Weiden</p>
                                <p v-if="selectedLanguage == 'EN'">Study of Business Administration at OTH Amberg-Weiden.</p>
                                <p v-if="selectedLanguage == 'TR'">OTH Amberg-Weiden'de İşletme Yönetimi eğitimi.</p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">Steuerberater seit 2019 (Mitglied Steuerberaterkammer München)</p>
                                <p v-if="selectedLanguage == 'EN'">Tax consultant since 2019 (Member of the Munich Chamber of Tax Consultants).</p>
                                <p v-if="selectedLanguage == 'TR'">2019 yılından beri vergi danışmanıyım (Münih Vergi Danışmanları Odası üyesi).</p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">Über 10 Jahre Berufserfahrung in einer der weltweit größten internationalen Wirtschaftsprüfungs- / Steuerberatungsgesellschaft („Big Four“)</p>
                                <p v-if="selectedLanguage == 'EN'">Over 10 years of professional experience in one of the world's largest international auditing/tax consulting firms ("Big Four").</p>
                                <p v-if="selectedLanguage == 'TR'">Dünyanın en büyük uluslararası denetim/vergi danışmanlık firmalarından birinde 10 yıldan fazla mesleki deneyime sahibim ("Big Four").</p>
							</v-list-item>
						</v-list>
					</template>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</v-col>
</v-row>
</v-container>
</v-row>
</v-container>
</section>
<section id="test">
	<v-parallax height="50vh" :src="homeImage"/>
</section>
<section id="services" style="min-height: 100vh">
	<div class="blocker"/>
	<v-container>
		<v-row justify="center">
			<v-col cols="12">
				<v-card elevation="16">
					<template v-slot:title>
					<p class="aboutme-title own-font" v-if="selectedLanguage == 'DE'">Leistungen</p>
                    <p class="aboutme-title own-font" v-if="selectedLanguage == 'EN'">Services</p>
                    <p class="aboutme-title own-font" v-if="selectedLanguage == 'TR'">Hizmetler</p>
				</template>
				<template v-slot:subtitle>
          <div v-if="!isWidthSizeLower">
				<p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'DE'">PRIVATPERSONEN, ARBEITNEHMER, RENTNER und FREIBERUFLER</p>
                <p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'EN'">INDIVIDUALS, EMPLOYEES, PENSIONERS and FREELANCERS</p>
                <p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'TR'">BİREYLER, ÇALIŞANLAR, EMEKLİLER ve SERBEST ÇALIŞANLAR</p>
          </div>          
          <div v-else>
				<p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'DE'">PRIVATPERSONEN, ARBEITNEHMER,</p>
        <p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'DE'">RENTNER und FREIBERUFLER</p>
                <p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'EN'">INDIVIDUALS, EMPLOYEES,</p>
                <p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'EN'">PENSIONERS and FREELANCERS</p>
                <p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'TR'">BİREYLER, ÇALIŞANLAR, </p>
                <p class="own-font aboutme-subtitle" v-if="selectedLanguage == 'TR'">EMEKLİLER ve SERBEST ÇALIŞANLAR</p>
          </div>

			</template>
			<template v-slot:text>
			<div class="infos-aboutme">
				<v-row>
					<v-col>
						<v-list lines="one" class="own-font begining-text">
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">
                                    Einkommensteuererklärungen mit Einkünften aus nichtselbständiger Arbeit, Kapitalvermögen, Vermietung und Verpachtung sowie sonstige Einkünfte z.B. aus Renten, Spekulationsgeschäften
                                </p>
                                <p v-if="selectedLanguage == 'EN'">
                                    Income tax returns with income from employment, capital assets, renting and leasing as well as other income, e.g. from pensions, speculative transactions
                                </p>
                                <p v-if="selectedLanguage == 'TR'">
                                    İstihdam, sermaye varlıkları, kiralamadan elde edilen gelirlerin yanı sıra emekli maaşları, spekülatif işlemler gibi diğer gelirleri içeren gelir vergisi beyannameleri
                                </p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right" v-if="selectedLanguage == 'DE'">
								<p v-if="selectedLanguage == 'DE'">
                                    Einnahmenüberschussrechnungen (EÜR)
                                </p>
							</v-list-item>
                            <v-list-item prepend-icon="mdi-chevron-right" v-if="selectedLanguage == 'EN'">
                                <p v-if="selectedLanguage == 'EN'">
                                    Excess income statements (EÜR)
                                </p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">
                                    Internationales Steuerrecht / Doppelbesteuerungsabkommen
                                </p>
                                <p v-if="selectedLanguage == 'EN'">
                                    International tax law / double taxation agreements
                                </p>
                                <p v-if="selectedLanguage == 'TR'">
                                    Uluslararası vergi hukuku / çifte vergilendirmeyi önleme anlaşmaları
                                </p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right" v-if="selectedLanguage == 'DE'">
								<p>
                                    Feststellungserklärungen für Grundstücks- oder Erbengemeinschaften
                                </p>
							</v-list-item>
                            <v-list-item prepend-icon="mdi-chevron-right" v-if="selectedLanguage == 'EN'">
                                <p v-if="selectedLanguage == 'EN'">
                                    Declarations of determination for real estate or communities of heirs
                                </p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">
                                    Prüfung von Steuerbescheiden
                                </p>
                                <p v-if="selectedLanguage == 'EN'">
                                    Examination of tax assessments
                                </p>
                                <p v-if="selectedLanguage == 'TR'">
                                    Vergi değerlendirmelerinin incelenmesi
                                </p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">
                                    Durchführung von außergerichtlichen Rechtsbehelfen (z.B. Einsprüche)
                                </p>
                                <p v-if="selectedLanguage == 'EN'">
                                    Carrying out out-of-court legal remedies (e.g. objections)
                                </p>
                                <p v-if="selectedLanguage == 'TR'">
                                    Mahkeme dışı hukuki yolların kullanılması (örn. itirazlar)
                                </p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">
                                    Beratungen zur steuerlichen Gestaltung (z.B. Abfindungen)
                                </p>
                                <p v-if="selectedLanguage == 'EN'">
                                    Advice on tax planning (e.g. severance payments)
                                </p>
                                <p v-if="selectedLanguage == 'TR'">
                                    Vergi planlamasına ilişkin tavsiyeler (örneğin kıdem tazminatı ödemeleri)
                                </p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">
                                    Erstellung von steuerlichen Anträgen (z.B. Lohnsteuerermäßigung)
                                </p>
                                <p v-if="selectedLanguage == 'EN'">
                                    Preparation of tax applications (e.g. income tax reduction)
                                </p>
                                <p v-if="selectedLanguage == 'TR'">
                                    Vergi başvurularının hazırlanması (örn. gelir vergisi indirimi)
                                </p>
							</v-list-item>
							<v-list-item prepend-icon="mdi-chevron-right">
								<p v-if="selectedLanguage == 'DE'">
                                    Grundsteuerreform
                                </p>
                                <p v-if="selectedLanguage == 'EN'">
                                    Property tax reform
                                </p>
                                <p v-if="selectedLanguage == 'TR'">
                                    Mülkiyet vergisi reformu
                                </p>
							</v-list-item>
						</v-list>
					</v-col>
				</v-row>
			</div>
		</template>
	</v-card>
</v-col>
</v-row>
</v-container>
</section>
<section id="test">
	<v-parallax height="50vh" :src="homeImage2" cover/>
</section>
<section id="contact" style="min-height: 100vh">
	<div class="blocker"/>
	<v-container class=" mb-6">
		<v-card elevation="16">
			<v-row>
				<v-col>
					<div class="mapouter">
						<div class="gmap_canvas">
							<iframe id="gmap_canvas" src="https://maps.google.com/maps?q=Steuerberatung%20Yusuf%20Kavalci&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
            </iframe>
							<br>
								<a href="https://www.embedgooglemap.net"/>
							</div>
						</div>
					</v-col>
				</v-row>
                <div v-if="!isWidthSizeLower">
				<v-row align="start">
					<v-col align="center">
						<v-sheet class="pa-2 ma-2">
							<v-btn class="ma-2 contact-icons" color="rgba(36, 45, 57, 0.9)" icon="mdi-map-marker-outline" variant="text" size="x-large"/>
							<ul class="own-font contact-text">
								<li>Haberspirker Str. 32</li>
								<li>86529 Schrobenhausen</li>
							</ul>
						</v-sheet>
					</v-col>
					<v-col align="center">
						<v-sheet class="pa-2 ma-2">
							<v-btn class="ma-2 contact-icons" color="rgba(36, 45, 57, 0.9)" icon="mdi-email-outline" variant="text" size="x-large"/>
							<ul class="own-font contact-text">
								<li>Telefon: <a href="tel:+4982529638812">08252 / 9638812</a></li>
								<li>Mobil: <a href="tel:+4917613697053">0176 / 13697053</a></li>
								<li>Email: info@steuerberatung-kavalci.de</li>
							</ul>
						</v-sheet>
					</v-col>
					<v-col align="center">
						<v-sheet class="pa-2 ma-2">
							<v-btn class="ma-2 contact-icons" color="rgba(36, 45, 57, 0.9)" icon="mdi-clock-outline" variant="text" size="x-large"/>
							<ul class="own-font contact-text">
								<li>Montag bis Freitag 9 bis 17 Uhr</li>
								<li>Samstag 9 bis 15 Uhr</li>
							</ul>
						</v-sheet>
					</v-col>
				</v-row>
                </div>
                <div v-else>
				<v-row align="center">
					<v-col align="center">
						<v-sheet class="pa-2 ma-2">
							<v-btn class="ma-2 contact-icons" color="rgba(36, 45, 57, 0.9)" icon="mdi-map-marker-outline" variant="text" size="x-large"/>
							<ul class="own-font contact-text">
								<li>Haberspirker Str. 32</li>
								<li>86529 Schrobenhausen</li>
							</ul>
						</v-sheet>
					</v-col>
					

				</v-row>
                <v-row align="center">
                    <v-col align="center">
						<v-sheet class="pa-2 ma-2">
							<v-btn class="ma-2 contact-icons" color="rgba(36, 45, 57, 0.9)" icon="mdi-email-outline" variant="text" size="x-large"/>
							<ul class="own-font contact-text">
								<li>Telefon: <a href="tel:+4982529638812">08252 / 9638812</a></li>
								<li>Mobil: <a href="tel:+4917613697053">0176 / 13697053</a></li>
								<li>Email: info@steuerberatung-kavalci.de</li>
							</ul>
						</v-sheet>
					</v-col>
                </v-row>
                <v-row align="center">
                    					<v-col align="center">
						<v-sheet class="pa-2 ma-2">
							<v-btn class="ma-2 contact-icons" color="rgba(36, 45, 57, 0.9)" icon="mdi-clock-outline" variant="text" size="x-large"/>
							<ul class="own-font contact-text">
								<li>Montag bis Freitag 9 bis 17 Uhr</li>
								<li>Samstag 9 bis 15 Uhr</li>
							</ul>
						</v-sheet>
					</v-col>
                </v-row>
                </div>
			</v-card>
		</v-container>
	</section>
	<section class="bg-grey-lighten-1">
		<v-row justify="center" no-gutters>
		<v-btn v-for="link in links" :key="link" class="mx-2" rounded="xl" variant="text" @click="openInNewTab(link.link)">
        {{ link.title }}
      </v-btn>
	</v-row>
</section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useWindowSize } from '@vueuse/core'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Home',
  components: {
  },
  data() {
    return {
      navigation: [
        {title: 'Startseite', titleEN: 'Home', titleTR: 'Anasayfa', link: 'home', color: '#fdfdfb', icon:'mdi-home', toggleId:'home'},
        {title: 'Über mich', titleEN: 'About Me', titleTR: 'Hakkımda', link: 'aboutme', color: '#a2a2a0', icon:'mdi-account-tie', toggleId:'aboutme'},
        {title: 'Leistungen',titleEN: 'Services', titleTR: 'Hizmetler', link: 'services', color: '#363636', icon: 'mdi-poll', toggleId:'services'},
        {title: 'Kontakt', titleEN: 'Contact', titleTR: 'Iletişim', link: 'contact', color: '#1f1e1e', icon: 'mdi-card-account-mail', toggleId:'contact' }
        ],
        toggle: 'home',
        homeImage: undefined,
        aboutMeImage: undefined,
        logo: undefined,
        kLogo: undefined,
        homeImage2: undefined,
        selectedLanguage: 'DE',
        windowSize: useWindowSize(),
         links: [ {title: 'Datenschutz', link: 'datenschutz'},
          {title: 'Impressum', link: 'impressum'}
      ],
      languages: ['DE', 'EN', 'TR']
    }
  },
  mounted() {
    this.homeImage = require("../assets/home.png")
    this.aboutMeImage = require("../assets/aboutme_h.png")
    this.logo = require("../assets/logo.png")
    this.kLogo = require("../assets/K.png")
    this.homeImage2 = require("../assets/home2.png")
  },
  computed: {
    isWidthSizeLower(): boolean {
      return this.windowSize.width < 1225;
    }
  },
  methods: {
      scrollIntoSection(id: string) {
        var elmnt = document.getElementById(id);
        console.log("goto");
        if (elmnt !== null) {
          elmnt.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
        }
      },
      openInNewTab(link: string) {
        const routeData = this.$router.resolve({name: link});
window.open(routeData.href, '_blank');
      },
      selectLanguage(lang: string) {
        console.log(lang);
        this.selectedLanguage = lang;
      }
  }
});
</script>

<style scoped>


.blocker {
  height: 150px !important;
}


.card-info {
    position: sticky;
    bottom: 77vh;
    left: 46vw;
    width: 39vw;
    height: 75vh;
}

.v-list-item-subtitle {
  font-size: 1.3em;
}











#main-doc {
  width: 100%;
  position: absolute;
}
#main-doc #nav-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  /*position: relative;*/
  width: 800px;
  height: 80px;
  background-color: rgba(36, 45, 57, 0.9);
  color: white;
  border-radius: 10px;
  box-shadow: 0 0 1.5px 1px rgba(255, 255, 255, 0.4), 0 0 1.5px 1px rgba(255, 255, 255, 0.4) inset;
}

#main-doc a {
  text-decoration: none;
  color: white;
  position: relative;
  display: inline-block;
}

#main-doc #nav-ul {
  margin-left: 1vw;
}
@media screen and (max-width: 560px) {
  #main-doc #nav-ul {
    margin-left: 54px;
  }
}
#main-doc #nav-ul li {
  list-style-type: none;
  font-family: "Roboto";
  font-size: 1.27rem;
  display: inline;
  margin: 16px;
}
@media screen and (max-width: 780px) {
  #main-doc #nav-ul li {
    font-size: 1.05rem;
    margin: 12px;
  }
}
@media screen and (max-width: 560px) {
  #main-doc #nav-ul li {
    font-size: 0.9rem;
    margin: 8px;
  }
}
#main-doc #nav-ul a {
  line-height: 52px;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}
#main-doc #nav-ul a:hover {
  background: white;
  color: #242d39;
}
#main-doc #nav-ul a:onclick {
  background: black;
  color: white;
}









#main-doc-mini {
  width: 100%;
  height: 100%;
  position: absolute;
}
#main-doc-mini #nav-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  /*position: relative;*/
  width: 200px;
  height: 50px;
  background-color: rgba(36, 45, 57, 0.9);
  color: white;
  border-radius: 10px;
  box-shadow: 0 0 1.5px 1px rgba(255, 255, 255, 0.4), 0 0 1.5px 1px rgba(255, 255, 255, 0.4) inset;
}

#main-doc-mini a {
  text-decoration: none;
  color: white;
  position: relative;
  display: inline-block;
}
#main-doc-mini .fa-github {
  font-size: 52px;
}

#main-doc-mini #nav-ul li {
  list-style-type: none;
  font-family: "Montserrat";
  font-size: 1.27rem;
  display: inline;
  margin: 16px;
}
#main-doc-mini #nav-ul a {
  line-height: 52px;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}
#main-doc-mini #nav-ul a:hover {
  background: white;
  color: #242d39;
}
#main-doc-mini #nav-ul a:onclick {
  background: black;
  color: white;
}








ul {
  list-style-type: none;
}



.gmap_canvas {
  overflow:hidden;
  background:none!important;
  height:30vh;
  width:100%;
  } 

  #gmap_canvas {
  overflow:hidden;
  background:none!important;
  height:30vh;
  width:100%;
  } 
  
  .mapouter{
    position:relative;
  height:30vh;
  width:100%;
    }

    .own-font {
      font-family: "Roboto", sans-serif;
      font-optical-sizing: auto ;
      font-style: normal;
    }

    .begining-text {
      font-size: 1.8em;
      line-height: 1;
          margin-top: 3vh;
              margin-bottom: 3vh;
    }

    .aboutme-title {
  font-size: 1.8em;
  margin-top: 2vh;
  line-height: 1.2;
  margin-top: 4vh;
  font-weight: bold;
}

.aboutme-subtitle {
  font-size: 1.3em;
  line-height: 1.2;
  font-weight: 100;
}

.contact-text {
  font-size: 1.4em;
}

.contact-icons {
  font-size: 3em;
}

.k-logo {
  width: 250px;
}


   @media screen and (max-width: 950px) {
    .begining-text {
      font-size: 1.3em;
      line-height: 1;
    }
        .aboutme-title {
      font-size: 1.3em;
    }
    .contact-text {
  font-size: 1em;
}
.contact-icons {
  font-size: 1.5em;
}
.k-logo {
  width: 150px;
}
.aboutme-subtitle {
  font-size: 0.9em;
}
   }

  @media screen and (max-width: 590px) {
    .begining-text {
      font-size: 1.0em;
      line-height: 1;
    }
        .aboutme-title {
      font-size: 1em;
    }
    .contact-text {
  font-size: 0.9em;
}
.k-logo {
  width: 100px;
}
.aboutme-subtitle {
  font-size: 0.8em;
}
   }

   .language-select {
    height: 40px !important;
    margin-left: 20px;
    z-index: 9999;
}
</style>
