import { createRouter, createWebHistory , RouteRecordRaw } from 'vue-router'
import Impressum from '../views/Impressum.vue'
import Home from '../views/Home.vue'
import Datenschutz from  '../views/Datenschutz.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: Impressum
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: Datenschutz
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
